<template>
    <div>
        <div class="tab">
            <el-tabs v-model="index_code" @tab-click="tabClickHandle" style="float:left;margin-top:10px;width:98.4%;position:relative;">
                <el-tab-pane name="FTSE" >
                    <span slot="label" style="margin-left:16px">{{ftse}}
                        <el-tooltip placement="bottom">
                            <div slot="content">英国富时100指数包含了伦敦证券交易所最大的100家上市公司，代表了伦交所约80%的市值，被认为是英国<br/>经济繁荣度的领先指标，是欧洲最被广泛使用的股票市场指数。</div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <Shang :data="ftse" ref="shang" v-if="'FTSE'==index_code"/>
                </el-tab-pane>
                <el-tab-pane name="GDAXI">
                    <span slot="label">{{gdaxi}}
                        <el-tooltip  placement="bottom">
                            <div slot="content">德国DAX指数是德意志交易所集团推出的蓝筹股指数，包含了30家主要的德国公司。DAX指数以市值加权，<br/>同时考虑到股息收入，名义上将所有股息收入再投资在股票上。</div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <Shang :data="gdaxi" ref="shang" v-if="'GDAXI'==index_code"/>
                </el-tab-pane>
                <el-tab-pane name="CAC40">
                    <span slot="label">{{fchi}}
                        <el-tooltip  placement="bottom">
                            <div slot="content">法国CAC40指数从在巴黎证券交易所的前100大上市公司中挑选了40只股票做为样本股，<br/>采用自由流通市值加权的方式编制，反映了法国证券市场的价格波动。</div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <Shang :data="fchi" ref="shang" v-if="'CAC40'==index_code"/>
                </el-tab-pane>
                <el-tab-pane name="FTSEMIB.MI">
                    <span slot="label">{{mi}}
                        <el-tooltip placement="bottom">
                            <div slot="content">富时意大利MIB指数是意大利股票市场主要的基准指数。它衡量了40只意大利股票的表现，这些股票占到了意大利约80%的国内市值。<br/>创造该指数是为了适合期货和期权的交易，作为ETF的基准指数，跟踪意大利股市的大市值股票。</div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <Shang :data="mi" ref="shang" v-if="'FTSEMIB.MI'==index_code"/>
                </el-tab-pane>                           
            </el-tabs>
            <span style="position:absolute;left:73%;height:50px;line-height:50px;">预测方法： 
                <el-radio-group v-model="method" @change="radioHangeHandler">
                    <el-radio label="garch_skewt">GARCH法</el-radio>
                    <el-radio label="ewma_hs">EWMA法</el-radio>
                </el-radio-group>                    
            </span>
        </div>
    </div>
</template>

<script>
import Shang from './shang'
export default {
    name: 'europe',
    data () {
        return {
            index_code: 'FTSE',
            method: 'garch_skewt',
            length: '1y',
            down_color: '#E64545',
            up_color: '#67B35A',
            ftse:'英国富时100',
            gdaxi:'德国DAX',
            fchi:'法国CAC40',
            mi:'富时意大利MIB',
            isHushenFont: false                 
        }
    },
    components:{
        Shang
    },       
    methods: { 
        changeData:function(){
            let params = {
                "index_code": this.index_code,
                "length": this.length,
                "method": this.method,
                "down_color": this.down_color,
                "up_color":this.up_color
            };     
            this.$refs.varEsEcharts.changeData(params) ;
            this.$refs.realizedVolEcharts.changeData(params) ;
        },
        initEcharts: function () {
            //调用子组件的函数，并把这个状态传过去。
            //this.changeData()
        },             
        radioHangeHandler: function (value) {
            this.method = value
            //调用子组件的函数，并把这个状态传过去。
            this.$refs.shang.changeData()
        }, 
        tabClickHandle: function (tab) {
            this.index_code = tab.name
            //调用子组件的函数，并把这个状态传过去。
            //this.changeData() 
        },
    }
}
</script>
<style lang='less'>
.el-tabs__item.is-active, .el-tabs__item:hover {
    color: #2E5082;
}
.el-radio__input.is-checked .el-radio__inner {
    border-color: #5F80A9;
    background: #5F80A9;
}
.el-radio__input.is-checked+.el-radio__label {
    color: #5F80A9;
}
.el-tabs__active-bar {
    background-color: #2E5082;
}
</style>
<style lang='less' scoped>
.tab{
    width:116.5%;
    height:50px;
    // padding:0 16px;
    background:#F7F7F7;
    position:relative;
    border-top:solid 1px #ddd;
}
</style>